
    import { Component, Vue, Prop } from 'vue-property-decorator';
    
    @Component({
        components: {
            
        },
    })
    export default class MatchingLocations extends Vue {

        @Prop({ default: null }) public matchingLocations: any;
        @Prop({ default: null }) public show: any;
        @Prop({ default: null }) public location: any;

        private setLocation(location: any) {
            this.$emit('locationIdChanged', location.id);
        }

        
        get filteredMatchingLocations() {
            return this.matchingLocations.slice(1);
        }


        private showDialog = false;

        private get localShow() {
            return this.show;
        }

        private set localShow(value: any) {
            this.$emit("showChanged", value);
        }
    }
