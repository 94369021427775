

    import { Action, Getter } from 'vuex-class';
    import { Component, Vue } from 'vue-property-decorator';


    const namespace = 'admin';

    @Component({
        components: {
        },
    })
    export default class AdminAddLogin extends Vue {
        @Action('addLogin', { namespace }) addLogin: any;
        @Action('newLogin', { namespace }) newLogin: any;
        @Getter('getLoginEditing', { namespace }) getLoginEditing: any;

        
        private panel: any = [0];

        private displayNameRules = [
            // @ts-ignore
            value => !!value || 'Displayname is required'
        ]

        private emailRules = [
            // @ts-ignore
            value => !!value || 'E-mail address is required',
         // @ts-ignore
           v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail address is invalid'
      
        ]

        private email() {
            if (this.getLoginEditing != null) {

                return "Login/email: " + this.getLoginEditing.email + "\r\n" + "Password: " + this.getLoginEditing.password;

            }
            else {
                return null;
            }

        }

        private encode() {
            if (this.getLoginEditing != null) {
                
                return { ID: this.getLoginEditing.id, IsAdmin: false, DisplayName: this.getLoginEditing.displayName, Email: this.getLoginEditing.email, Password: this.getLoginEditing.password }
               
            }
            else {
                return null;
            }
        }
    }

