export default (value: any, locale: any, digits: number) => {
    if (typeof value !== "number") {
        return value;
    }
    const formatter = new Intl.NumberFormat(locale, {
        
        minimumFractionDigits: digits,
        maximumFractionDigits: digits

    });
    return formatter.format(value);
};
