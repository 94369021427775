import { format } from 'date-fns';
import { nl, enUS, fr, es } from 'date-fns/locale';

export default (date: Date, locale: any) => {
    let localeObject = nl;
    if (locale === 'nl') {
        localeObject = nl;
    }
    if (locale === 'en') {
        localeObject = enUS;
    }
    if (locale === 'es') {
        localeObject = es;
    }
    if (locale === 'fr') {
        localeObject = fr;
    }
    const returnValue = format(new Date(date), 'd MMMM yyyy HH:mm', { locale: localeObject });
    return returnValue.charAt(0).toUpperCase() + returnValue.slice(1);
};
