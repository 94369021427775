
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

    @Component
    export default class ScenarioProcessingLog extends Vue {

        private modalShow = false;

        @Prop({ default: null }) public scenarioProcessingLog: any;
        @Prop({ default: null }) public scenario: any;
        @Prop({ default: null }) public model: any;
       
        @Watch('scenarioProcessingLog')
        private onModelParametersChanged(value: any) {
            if (value) {
                this.modalShow = true;
            }
            else {
                this.modalShow = false;
            }
        }

        get headers(): any {
            const h = [
                {
                    text: this.$t("message.scenario"),
                    align: 'start',
                    sortable: false,
                    value: 'scenarioName',
                },
                {
                    text: this.$t("message.scheduledDateTimeUtc"),
                    align: 'start',
                    sortable: false,
                    value: 'scheduledDateTimeUtc',
                },
                {
                    text: this.$t("message.startedDateTimeUtc"),
                    align: 'start',
                    sortable: false,
                    value: 'startedDateTimeUtc',
                },
                {
                    text: this.$t("message.isoLinesMissingDateTimeUtc"),
                    align: 'start',
                    sortable: false,
                    value: 'isoLinesMissingDateTimeUtc',
                },
                
                {
                    text: this.$t("message.endedDateTimeUtc"),
                    align: 'start',
                    sortable: false,
                    value: 'endedDateTimeUtc',
                },
                {
                    text: this.$t("message.user"),
                    align: 'start',
                    sortable: false,
                    value: 'userName',
                }
                
            ];
            if (this.model.userIsGlobalAdmin) {
                h.push({
                    text: this.$t("message.error"),
                    align: 'start',
                    sortable: false,
                    value: 'error',
                }
                )
            }
            return h;
        }

        private close() {
            this.modalShow = false;
        }
    }
