
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import PropertyValue from '@/components/main/PropertyValue.vue'
    import Properties from '@/components/main/Properties.vue'

    @Component({
        components: {
            PropertyValue,
            Properties,
        },
    })
    export default class CalculationModelOutput extends Vue {
        @Prop({ default: null }) public model: any;
        @Prop({ default: null }) public subModel: any;
        @Prop({ default: null }) public location: any;
        @Prop({ default: null }) public locationProperties: any;

        private selectViewLocation(value: any) {
            return value.viewLocation == 1;
        }
    }
