
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
    
    @Component
    export default class EditDrivers extends Vue {

        private modalShow = false;

        @Prop({ default: null }) public driverFilters: any;
        @Prop({ default: null }) public model: any;
        mounted() {
            this.$on('escKey' + this.$options.name, () => {
                if (this.modalShow) this.close();
            });
            document.addEventListener("keydown", (e) => {
                if (e.keyCode == 27) {
                    this.$emit('escKey' + this.$options.name);
                }
            });
        }

        @Watch('driverFilters')
        private onDriversChanged(value: any) {
            if (value) {
                this.modalShow = true;
            }
            else {
                this.modalShow = false;
            }
        }

        private async close() {
            const res = await this.$confirm(String(this.$t('message.confirmClose')), { buttonTrueText: String(this.$t('message.yes')), buttonFalseText: String(this.$t('message.no')), title: String(this.$t('message.warning')) });
            if (res) { this.modalShow = false }
        }

        private save() {
            this.$emit('save', this.driverFilters);
        }

        private async removeDriverFilter(driverFilter: any) {
            const res = await this.$confirm('Weet u zeker dat u deze rij wilt verwijderen?', { buttonTrueText: String(this.$t('message.yes')), buttonFalseText: String(this.$t('message.no')), title: String(this.$t('message.warning')) })
            if (res) {
                for (let i = 0; i < this.driverFilters.length; i++) {
                    if (this.driverFilters[i] === driverFilter) {
                        this.driverFilters.splice(i, 1);
                    }
                }
            }
        }

        private addDriverFilter() {
            this.driverFilters.push({ id: -1, function: '', title: '', drivers: [] });
        }

        private async removeDriver(driverFilter: any, driver: any) {
            const res = await this.$confirm('Weet u zeker dat u deze rij wilt verwijderen?', { buttonTrueText: String(this.$t('message.yes')), buttonFalseText: String(this.$t('message.no')), title: String(this.$t('message.warning')) })
            if (res) {
                for (let i = 0; i < driverFilter.drivers.length; i++) {
                    if (driverFilter.drivers[i] === driver) {
                        driverFilter.drivers.splice(i, 1);
                    }
                }
            }
        }

        private addDriver(driverFilter: any) {
            driverFilter.drivers.push({ id: -1, function: '', title: '', roundingDigits: 2, inverse: false });
        }

        private encode() {
            if (this.driverFilters != null) {
                const f1 = this.driverFilters.map(function (f: any) {
                    return {
                        Title: f.title,
                        Function: f.function,
                        Drivers: f.drivers.map(function (f: any) {
                            return { Title: f.title, Function: f.function, Weight: f.weight, RoundingDigits: f.roundingDigits, Inverse: f.inverse };
                        })
                    };
                });
                return f1;
            }
            else {
                return null;
            }
        }
    }
