
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
    
    @Component
    export default class EditNormalizedScoresFunctions extends Vue {

        private modalShow = false;

        @Prop({ default: null }) public normalizedScoresFunctions: any;
        @Prop({ default: null }) public model: any;
       

        mounted() {
            this.$on('escKey' + this.$options.name, () => {
                if (this.modalShow) this.close();
            });
            document.addEventListener("keydown", (e) => {
                if (e.keyCode == 27) {
                    this.$emit('escKey' + this.$options.name);
                }
            });
        }

        @Watch('normalizedScoresFunctions')
        private onNormalizedScoresFunctionsChanged(value: any) {
            
            if (value) {
                this.modalShow = true;
            }
            else {
                this.modalShow = false;
            }
        }

        private async close() {
            const res = await this.$confirm(String(this.$t('message.confirmClose')), { buttonTrueText: String(this.$t('message.yes')), buttonFalseText: String(this.$t('message.no')), title: String(this.$t('message.warning')) });
            if (res) { this.modalShow = false }
        }

        private save() {
            this.$emit('save', this.normalizedScoresFunctions);
        }

        private async removeLine(row: any) {
            const res = await this.$confirm('Weet u zeker dat u deze rij wilt verwijderen?', { buttonTrueText: String(this.$t('message.yes')), buttonFalseText: String(this.$t('message.no')), title: String(this.$t('message.warning')) })
            if (res) {
                for (let i = 0; i < this.normalizedScoresFunctions.length; i++) {
                    if (this.normalizedScoresFunctions[i] === row) {
                        this.normalizedScoresFunctions.splice(i, 1);
                    }
                }
            }
        }
        private addLine() {
            this.normalizedScoresFunctions.push({ id: -1, defaultValue: 1, type: 1});
        }


        private encode() {
            if (this.normalizedScoresFunctions != null) {
                const f1 = this.normalizedScoresFunctions.map(function (f: any) {
                    return { Title: f.title, Function: f.function, Weight: f.weight }
                });
                return f1;
            }
            else {
                return null;
            }
        }
    }
