export function filterPointTypeGeometries(features: any) {
    features.forEach(function (feature: any) {
        const geometryType = feature.geometry.type;
        if (geometryType === "GeometryCollection") {
            //Filter out point type geometries from the geometrycolleciton
            feature.geometry.geometries = feature.geometry.geometries.filter(function (geometry: any) {
                return geometry.type !== "Point";
            });
        }
    });
    return features.filter(function (feature: any) {
        return feature.geometry.type !== "Point";
    });
}

export function cloneLocation(location: any) {
    const newLocation = Object.assign({}, location);
    newLocation.marker = null;
    return newLocation;
}