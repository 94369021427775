
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import ScenarioStatus from '@/components/main/ScenarioStatus.vue';
    @Component({
        name: 'ScenarioTree',
        components: {
            ScenarioTree,
            ScenarioStatus
        },
    })
    export default class ScenarioTree extends Vue {
        
        @Prop({ default: 0 }) public nodes: any;
        @Prop({ default: 0 }) public scenarios: any;

        private setScenario(scenario: any) {
            this.$emit('setScenario', scenario);
        }

    }
