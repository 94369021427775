import { ActionTree } from 'vuex';
import axios from 'axios';
import { Expression } from 'ncalcjs';
import { MainState } from './types';
import { RootState } from '../types';
import Vue from 'vue'
import { router } from "../../main";
import { filterPointTypeGeometries,cloneLocation } from '../shared';

export const actions: ActionTree<MainState, RootState> = {
    async fetchModelParameters(context) {
        context.commit('SET_STARTBUSY', null, { root: true });
        axios.get('/api/models/' + context.state.subModel.id + '/model-parameters').then(async response => {
            context.commit('SET_MODELPARAMETERS', response.data);
            context.commit('SET_ENDBUSY', null, { root: true });
        }).catch((error) => {
            context.commit('SET_ERROR', error, { root: true })
        })
    },
    async saveMergingScenario(context) {
        context.commit('SET_STARTBUSY', null, { root: true });
        let locations = '';
        context.state.scenarioMerging.selectedLocations.forEach((location: any) => {
            locations += '&locations=' + location;
        });
        await axios.put('/api/models/scenarios/' + context.state.scenarioMerging.scenarioId + '/copy-changes/' + context.state.scenarioMerging.destinationScenarioId + '?deleteSourceScenario=' + context.state.scenarioMerging.deleteSourceScenario + locations).then(async response => {
            
            await context.dispatch('fetchScenarios');
            context.dispatch('setScenarioById', context.state.scenarioMerging.destinationScenarioId);
            context.commit('SET_SCENARIOMERGING', null);
            context.commit('SET_ENDBUSY', null, { root: true });
        }).catch((error) => {
            context.commit('SET_ERROR', error, { root: true });
        })
    },
    async mergeScenario(context, payload) {
        context.commit('SET_STARTBUSY', null, { root: true });
        context.commit('SET_SCENARIOMERGING', payload);
        context.commit('SET_ENDBUSY', null, { root: true });
    },
    async fetchScenarioProcessingLog(context) { 
        context.commit('SET_STARTBUSY', null, { root: true });
        axios.get('/api/models/' + context.state.model.id + '/processing-log').then(async response => {
            context.commit('SET_SCENARIOPROCESSINGLOG', response.data);
            context.commit('SET_ENDBUSY', null, { root: true });
        }).catch((error) => {
            context.commit('SET_ERROR', error, { root: true })
        })
    },
    async fetchLog(context, filter) { 
        if (filter == null) filter = { eventType: 255, source: '' }
        context.commit('SET_STARTBUSY', null, { root: true });
        axios.get('/api/admin/log?eventType=' + filter.eventType + "&source=" + filter.source).then(async response => {
            context.commit('SET_LOG', response.data);
            context.commit('SET_ENDBUSY', null, { root: true });
        }).catch((error) => {
            context.commit('SET_ERROR', error, { root: true })
        })
    },
    async fetchNormalizedScoresFunctions(context) { //, onlyStatus
        context.commit('SET_STARTBUSY', null, { root: true });
        axios.get('/api/models/' + context.state.model.id + '/normalized-scores-functions').then(async response => {
            context.commit('SET_NORMALIZEDSCORESFUNCTIONS', response.data);
            context.commit('SET_ENDBUSY', null, { root: true });
        }).catch((error) => {
            context.commit('SET_ERROR', error, { root: true })
        })
    },
    async saveModelParameters(context) {
        context.commit('SET_STARTBUSY', null, { root: true });
        const functions = context.state.modelParameters.functions.map(function (f: any) {
            return f.function;
        });
        await axios.put('/api/functions/validate', functions).then(async response => {
            if (response.data.length > 0) {
                context.commit('SET_ERROR', response.data[0], { root: true })
            }
            else {
                await axios.put('/api/models/' + context.state.subModel.id + '/model-parameters', context.state.modelParameters).then(function () {
                    context.commit('SET_MODELPARAMETERS', null);
                    context.commit('SET_ENDBUSY', null, { root: true });
                }).catch((error) => {
                    context.commit('SET_ERROR', error, { root: true })
                })
            }
        });
    },
    async saveNormalizedScoresFunctions(context) {
        context.commit('SET_STARTBUSY', null, { root: true });
        const functions = context.state.normalizedScoresFunctions.map(function (f: any) {
            return f.function;
        });
        await axios.put('/api/functions/validate', functions).then(async response => {
            if (response.data.length > 0) {
                context.commit('SET_ERROR', response.data[0], { root: true })
            }
            else {
                await axios.put('/api/models/' + context.state.model.id + '/normalized-scores-functions', context.state.normalizedScoresFunctions).then(function () {
                    context.commit('SET_NORMALIZEDSCORESFUNCTIONS', null);
                    context.commit('SET_ENDBUSY', null, { root: true });
                }).catch((error) => {
                    context.commit('SET_ERROR', error, { root: true })
                })
            }
        }).catch((error) => {
            context.commit('SET_ERROR', error, { root: true })
        })
    },
    async fetchDrivers(context) {
        context.commit('SET_STARTBUSY', null, { root: true });
        axios.get('/api/models/' + context.state.model.id + '/drivers').then(async response => {
            context.commit('SET_DRIVERS', response.data);
            context.commit('SET_ENDBUSY', null, { root: true });
        }).catch((error) => {
            context.commit('SET_ERROR', error, { root: true })
        })
    },
    async saveDrivers(context) {
        context.commit('SET_STARTBUSY', null, { root: true });
        const functions = context.state.drivers.map(function (f: any) {
            return f.function;
        });
        await axios.put('/api/functions/validate', functions).then(async response => {
            if (response.data.length > 0) {
                context.commit('SET_ERROR', response.data[0], { root: true })
            }
            else {
                await axios.put('/api/models/' + context.state.model.id + '/drivers', context.state.drivers).then(function () {
                    context.commit('SET_DRIVERS', null);
                    context.commit('SET_ENDBUSY', null, { root: true });
                }).catch((error) => {
                    context.commit('SET_ERROR', error, { root: true })
                })
            }
        }).catch((error) => {
            context.commit('SET_ERROR', error, { root: true })
        })
    },
    async searchByBoundingBox(context, payload) {
        context.commit('SET_STARTBUSY', null, { root: true });
        if (payload == null || payload.trim() == '') {
            context.commit('SET_SEARCHRESULTS', []);
            context.commit('SET_ENDBUSY', null, { root: true });
        }
        else {
            await axios.get('/api/search/' + context.state.mapBounds.north + '/' + context.state.mapBounds.west + '/' + context.state.mapBounds.south + '/' + context.state.mapBounds.east + '/' + payload).then(async response => {
                context.commit('SET_SEARCHRESULTS', response.data);
                context.commit('SET_ENDBUSY', null, { root: true });
            }).catch((error) => {
                context.commit('SET_ERROR', error, { root: true })
            })
        }
    },
    async filterLocations(context, payload) {
        context.state.filterLocations = payload;
    },
    async filterScenarios(context, payload) {
        context.state.filterScenarios = payload;
    },
    async fetchPois(context) {
        context.commit('SET_STARTBUSY', null, { root: true });
        const promises = context.state.poiSetsVisible.filter(function (poiSet: any) { return poiSet > 0 } ).map(async function (poiSet: any) {
            try {
                const response = await axios.get('/api/models/' + context.state.model.id + '/poisets/' + poiSet + '/pois');
                response.data.forEach(function (point: any) {
                    point["lat"] = point.latitude;
                    point["lng"] = point.longitude;
                    point["coords"] = [point.longitude, point.latitude];
                })
                return response.data;
            } catch (error) {
                context.commit('SET_ERROR', error, { root: true });
            }
        })
        const results = await Promise.all(promises);
        context.commit('SET_POIS', results.flat() ?? []);
        context.commit('SET_ENDBUSY', null, { root: true });
    },
    async fetchHeatMapPoints(context,) {
        context.commit('SET_STARTBUSY', null, { root: true });
        const heatMapPoints: any = [];
        await context.state.heatMapSetsVisible.forEach(async function (heatMapSet: any) {
            if (heatMapSet > 0) {
                context.commit('SET_STARTBUSY', null, { root: true });
                await axios.get('/api/models/' + context.state.model.id + '/heatmapsets/' + heatMapSet + '/points').then(async response => {
                    heatMapPoints.push(response.data);
                    context.commit('SET_ENDBUSY', null, { root: true });
                }).catch((error) => {
                    context.commit('SET_ERROR', error, { root: true })
                })
            }

        });
        context.commit('SET_HEATMAPPOINTS', heatMapPoints);
        context.commit('SET_ENDBUSY', null, { root: true });
    },
    async fetchModels(context) {
        context.commit('SET_STARTBUSY', null, { root: true });
        return axios.get('/api/models').then(async response => {
            if (response.data.length == 0) {
                //No models found
                context.commit('SET_DATABASESTATUS', 2, { root: true });
            }
            else {
                context.commit('SET_MODELS', response.data);

                if (router.currentRoute.query.subModelId != null) {
                    await context.dispatch('setModelById', router.currentRoute.query.modelId);
                } else if (context.state.subModel == null) {
                    await context.dispatch('setModel', response.data[0]);
                }
                setInterval(function () {
                    if ((Date.now() - context.rootState.lastUserActionDateTime) > 5000) {
                        context.dispatch('fetchScenarios', true)
                    }
                }, 10000)
            }
            context.commit('SET_ENDBUSY', null, { root: true });
        }).catch((error) => {
            console.error(error);
            context.commit('SET_ERROR', error, { root: true })
        })
    },
    async setModel(context, model) {

        context.commit('SET_STARTBUSY', null, { root: true });
        await axios.get('/api/models/' + model.id + '/location-groups').then(response => {
            model['locationGroups'] = response.data;
            
        }).catch((error) => {
            context.commit('SET_ERROR', error, { root: true })
        })
        await axios.get('/api/models/' + model.id + '/isolines-parameters').then(response => {
            context.commit('SET_ISOLINEPARAMETERS', response.data);
        }).catch((error) => {
            context.commit('SET_ERROR', error, { root: true })
        })
        context.commit('SET_LOCATIONCATCHMENTAREA', null);
        context.commit('SET_MATCHINGLOCATIONS', null);
        context.commit('SET_LOCATION', null);
        context.commit('SET_SCENARIO', null);
        context.commit('SET_SUBMODEL', null);
        context.commit('SET_AREA', null);
        context.commit('SET_AREASETSVISIBLE', []);
        context.commit('SET_POISETSVISIBLE', []);
        context.commit('SET_HEATMAPSETSVISIBLE', []);
        context.commit('SET_STANDBYFILTERLOCATIONS', null);
        context.commit('SET_STANDBYFILTERSCENARIOS', null);
        context.commit('SET_POIS', []);
        context.commit('SET_HEATMAPPOINTS', []);
        context.commit("SET_CATCHMENTAREA", null);
        context.state.filterLocations = '';
        context.state.filterScenarios = '';
        model["catchmentAreasOnMap"] = model.catchmentAreas.filter(function (catchmentArea: any) {
            return catchmentArea.onMap == true;
        });
        context.commit('SET_MODEL', model);
        if (model.catchmentAreaMode == 0) {
            context.commit("SET_CATCHMENTAREA", { impact: false, coverage: 1.0, catchmentAreaId: model.catchmentAreas[0].id, areaSetId: model.catchmentAreas[0].areaSetId, isoLineSetId: model.catchmentAreas[0].isoLineSetId });

        } else {
            if (model.catchmentAreasOnMap.length > 0) {
                context.commit("SET_CATCHMENTAREA", { impact: false, coverage: 1.0, catchmentAreaId: model.catchmentAreasOnMap[0].id, areaSetId: model.catchmentAreasOnMap[0].areaSetId, isoLineSetId: model.catchmentAreasOnMap[0].isoLineSetId });
            }
        }
        context.commit('SET_ISOLINESET', model.isoLineSets[0]);
        context.commit('SET_ISOLINEMINUTES', 0);
        await context.dispatch('fetchSubModels');
        await context.dispatch('fetchScenarios');
        context.commit('SET_ENDBUSY', null, { root: true });
    },
    async fetchSubModels(context, onlyStatus) {
        if (!onlyStatus) context.commit('SET_STARTBUSY', null, { root: true });
        await axios.get('/api/models/' + context.getters.getModel.id + '/submodels').then(async response => {
            context.commit('SET_SUBMODELS', response.data);
            if (router.currentRoute.query.subModelId != null) {
                context.dispatch('setSubModelById', router.currentRoute.query.subModelId);
            } else if (context.state.subModel == null) {
                await context.dispatch('setSubModel', response.data[0]);
            }
            context.commit('SET_ENDBUSY', null, { root: true });
        }).catch((error) => {
            context.commit('SET_ERROR', error, { root: true })
        })
    },
    async setSubModel(context, subModel) {
        await context.commit('SET_SUBMODEL', subModel);
        context.dispatch('updatePath');
    },
    async setSubModelById(context, subModelId) {
        let subModelToSet: any = null;
        context.state.subModels.forEach(function (subModel: any) {
            if (subModelToSet == null) subModelToSet = subModel;
            if (subModel.id == subModelId) {
                subModelToSet = subModel;
                return;
            }
        });
        context.dispatch('setSubModel', subModelToSet);
    },
    async setModelById(context, modelId) {
        let modelToSet: any = null;
        context.state.models.forEach(function (model: any) {
            if (modelToSet == null) modelToSet = model;
            if (model.id == modelId) {
                modelToSet = model;
                return;
            }
        });
        context.dispatch('setModel', modelToSet);
    },
    async fetchScenarios(context, onlyStatus = false) {
        if (onlyStatus == true && context.state.scenario) {
            await axios.get('/api/models/' + context.getters.getModel.id + "/scenarios/only-status").then(async response => {
                response.data.forEach(function (scenario: any) {
                    let setLocation = false;
                    if (scenario.id == context.state.scenario.id && scenario.status != context.state.scenario.status && scenario.status == 9) {
                        if (context.state.location != null) setLocation = true;
                    }
                    context.state.scenarios.forEach(function (existingScenario: any) {
                        if (existingScenario.id == scenario.id) {
                            existingScenario.status = scenario.status;
                            context.state.readOnly = !(scenario.status == 0 || scenario.status == 9 || scenario.status == 10);
                        }
                    });
                    //The location needs to be set after the status of the scenario is set.
                    if (setLocation) context.dispatch('setLocation', context.state.location);
                });
            }).catch((error) => {
                context.commit('SET_ERROR', error, { root: true })
            })
        }
        if (onlyStatus == false) {
            context.commit('SET_STARTBUSY', null, { root: true });
            await axios.get('/api/models/' + context.getters.getModel.id + "/scenarios").then(async response => {
                context.commit('SET_SCENARIOS', response.data);
                if (router.currentRoute.query.scenarioId != null) { // && router.currentRoute.query.scenarioId != '-1'
                    context.dispatch('setScenarioById', router.currentRoute.query.scenarioId);
                } else if (context.state.scenario == null) {
                    context.state.scenarios.forEach(async function (scenario: any) {
                        if (scenario.parentScenarioId == null) {
                            await context.dispatch('setScenario', scenario);
                        }
                    });
                }
                context.commit('SET_ENDBUSY', null, { root: true });
            }).catch((error) => {
                context.commit('SET_ERROR', error, { root: true })
            })
        }
    },
    async fetchScenarioLocations(context, scenario) {
        context.commit('SET_STARTBUSY', null, { root: true });
        await axios.get('/api/models/scenarios/' + scenario.id + '/changed-locations').then(async response => {
            response.data.forEach(function (location: any) {
                location["lat"] = location.latitude;
                location["lng"] = location.longitude;
                location["coords"] = [location.longitude, location.latitude];
            });
            context.commit('SET_SCENARIOLOCATIONS', response.data);
            context.commit('SET_ENDBUSY', null, { root: true });
        }).catch((error) => {
            context.commit('SET_ERROR', error, { root: true })
        })
    },
    async setScenario(context, scenario) {
        await context.commit('SET_SCENARIO', scenario);
        if (context.state.fetchCatchmentAreaBusy != null) {
            context.state.fetchCatchmentAreaBusy.cancel();
        }
        context.state.readOnly = !(scenario.status == 0 || scenario.status == 9 || scenario.status == 10);
        context.dispatch('updatePath');
        await context.dispatch('fetchLocations');
        await context.dispatch('fetchScenarioLocations', scenario);
    },
    async setScenarioById(context, scenarioId) {
        let scenarioToSet: any = null;
        context.state.scenarios.forEach(function (scenario: any) {
            if (scenarioToSet == null) scenarioToSet = scenario;
            if (scenario.id == scenarioId) {
                scenarioToSet = scenario;
                return;
            }
        });
        await context.dispatch('setScenario', scenarioToSet);
    },
    async fetchLocations(context) {
        if (context.state.scenario != null) {
            context.commit('SET_STARTBUSY', null, { root: true });
            return await axios.get('/api/models/scenarios/' + context.state.scenario.id + '/locations').then(response => {
                response.data.forEach(function (location: any) {
                    location["lat"] = location.latitude;
                    location["lng"] = location.longitude;
                    location["coords"] = [location.longitude, location.latitude];
                    location["values"] = null;
                })
                context.commit('SET_LOCATIONS', response.data.sort(function (a: any, b: any) {
                    const nameA = a.name.toUpperCase(); // ignore upper and lowercase
                    const nameB = b.name.toUpperCase(); // ignore upper and lowercase
                    return nameA.localeCompare(nameB);

                }));
                if (router.currentRoute.query.locationId != null) {
                    context.dispatch('setLocationById', router.currentRoute.query.locationId);
                }
                context.commit('SET_ENDBUSY', null, { root: true });
                context.commit('SET_DATABASESTATUS', 2, { root: true });
            }).catch((error) => {
                context.commit('SET_ERROR', error, { root: true })
            })
        }
    },
    async fetchMatchingLocations(context) {
        if (context.state.location != null && context.state.scenario != null) {
            context.commit('SET_STARTBUSY', null, { root: true });
            return axios.get('/api/models/' + context.state.subModel.id + '/scenarios/' + context.state.scenario.id + '/locations/' + context.state.location.id + "/matching-locations?topN=11").then(response => {
                context.commit('SET_MATCHINGLOCATIONS', response.data);
                context.commit('SET_ENDBUSY', null, { root: true });
            }).catch((error) => {
                context.commit('SET_ERROR', error, { root: true })
            })
        }
        else {
            context.commit('SET_MATCHINGLOCATIONS', null);
        }
    },
    async fetchMatchingScores(context) {
        if (context.state.location != null && context.state.scenario != null) {
            context.commit('SET_STARTBUSY', null, { root: true });
            return axios.get('/api/models/' + context.state.subModel.id + '/scenarios/' + context.state.scenario.id + '/locations/' + context.state.location.id + "/matching-scores?topN=6").then(response => {
                context.commit('SET_MATCHINGSCORES', response.data);
                context.commit('SET_ENDBUSY', null, { root: true });
            }).catch((error) => {
                context.commit('SET_ERROR', error, { root: true })
            })
        }
        else {
            context.commit('SET_MATCHINGSCORES', null);
        }
    },
    async fetchOnlinePois(context) {
        return; //skip this part now
        if (context.state.location != null && context.state.scenario != null) {
            context.commit('SET_STARTBUSY', null, { root: true });
            return axios.get('/api/models/onlinepois/' + context.state.location.id).then(response => {
                response.data.forEach(function (point: any) {
                    point["lat"] = point.latitude;
                    point["lng"] = point.longitude;
                    point["coords"] = [point.longitude, point.latitude];
                })
                context.commit('SET_LOCATIONONLINEPOIS', response.data);
                context.commit('SET_ENDBUSY', null, { root: true });
            }).catch((error) => {
                context.commit('SET_ERROR', error, { root: true })
            })
        }
        else {
            context.commit('SET_LOCATIONONLINEPOIS', null);
        }
    },
    async fetchLocationScores(context) {
        context.commit('SET_STARTBUSY', null, { root: true });
        axios.get('/api/models/' + context.state.subModel.id + '/scenarios/' + context.state.scenario.id + '/locations/' + context.state.location.id + '/scores').then(response => {
                context.commit('SET_LOCATIONSCORES', response.data);
                context.commit('SET_ENDBUSY', null, { root: true });
            }).catch((error) => {
                context.commit('SET_ERROR', error, { root: true })
            })
    },
    async fetchDriversForLocation(context) {
        if (context.state.location != null && context.state.scenario != null) {
            context.commit('SET_STARTBUSY', null, { root: true });
            return axios.get('/api/models/scenarios/' + context.state.scenario.id + '/locations/' + context.state.location.id + '/drivers').then(response => {
                context.commit('SET_LOCATIONDRIVERS', response.data);
                context.commit('SET_ENDBUSY', null, { root: true });
            }).catch((error) => {
                context.commit('SET_ERROR', error, { root: true })
            })
        }
        else {
            context.commit('SET_LOCATIONDRIVERS', null);
        }
    },
    async updatePath(context) {

        let path = '/';
        if (context.state.model != null) path += '?modelId=' + context.state.model.id; else if (router.currentRoute.query.modelId != null) path += "?modelId=" + router.currentRoute.query.modelId; else path += '?modelId=-1';
        if (context.state.subModel != null) path += '&subModelId=' + context.state.subModel.id; else if (router.currentRoute.query.subModelId != null) path += "&subModelId=" + router.currentRoute.query.subModelId; //else path += '&subModelId=-1';
        if (context.state.scenario != null) path += '&scenarioId=' + context.state.scenario.id; else if (router.currentRoute.query.scenarioId != null) path += "&scenarioId=" + router.currentRoute.query.scenarioId; //else path += '&scenarioId=-1';
        if (context.state.location != null) path += '&locationId=' + context.state.location.id; else if (router.currentRoute.query.locationId != null) path += "&locationId=" + router.currentRoute.query.locationId; //else path += '&locationId=-1';
        router.push(path).catch(err => {
            // Ignore the vuex err regarding  navigating to the page they are already on.
            if (err.name != "NavigationDuplicated") {
                // But print any other errors to the console
                console.error(err);
            }
        })
    },
    async setLocationByIdWithFocus(context, locationId) {
        let locationToSet: any = null;
        if (context.state.locations != null && locationId != null) {
            context.state.locations.forEach(function (location: any) {
                if (location.id == locationId) {
                    locationToSet = location;
                    locationToSet["zoomTo"] = true;
                    return;
                }
            });
        }
        context.dispatch('setLocation', locationToSet);
    },
    async setLocationById(context, locationId) {
        let locationToSet: any = null;
        if (context.state.locations != null && locationId != null) {
            context.state.locations.forEach(function (location: any) {
                if (location.id == locationId) {
                    locationToSet = location;
                    return;
                }
            });
        }
        context.dispatch('setLocation', locationToSet);
    },
    async setLocation(context, location) {
        context.commit('SET_STARTBUSY', null, { root: true });
        if (context.state.location == location) {
            location = null;    
            if (context.state.fetchCatchmentAreaBusy != null) {
                context.state.fetchCatchmentAreaBusy.cancel();
            }
        }
        context.commit('SET_LOCATION', location);
        context.dispatch('updatePath');
        context.commit('SET_LOCATIONPHOTO', null);
        context.commit('SET_LOCATIONONLINEPOIS', null);
        context.commit('SET_LOCATIONISOLINES', null);
        context.commit('SET_ISOLINEMINUTES', 0);
        context.commit('SET_LOCATIONPROPERTIES', null);
        context.commit('SET_LOCATIONCATCHMENTAREA', null);
        context.commit('SET_MATCHINGLOCATIONS', null);
        if (location != null && location.visibility == 0) {
            context.dispatch('fetchMatchingLocations');
            context.dispatch('fetchMatchingScores');
            context.dispatch('fetchOnlinePois');
            if (context.state.scenario.status == 9) {

                context.dispatch('fetchCatchmentArea')
            }
            context.commit('SET_STARTBUSY', null, { root: true });
            axios.get('/api/models/scenarios/' + context.state.scenario.id + '/locations/' + location.id + '/properties').then(response => {
                context.dispatch('setLocationPropertiesVisibility', response.data);
                context.commit('SET_LOCATIONPROPERTIES', response.data);
                context.commit('SET_ENDBUSY', null, { root: true });
            }).catch((error) => {
                context.commit('SET_ERROR', error, { root: true })
            })
            if (context.state.model.displayLocationPhoto) {
                context.commit('SET_STARTBUSY', null, { root: true });
                axios.get('/api/models/scenarios/locations/' + location.id + '/photo').then(response => {
                    context.state.locationPhoto = response.data;
                    context.commit('SET_ENDBUSY', null, { root: true });
                }).catch((error) => {
                    context.commit('SET_ERROR', error, { root: true })
                })
            }
        }
        context.commit('SET_ENDBUSY', null, { root: true });
    },
    async setLocationPropertiesVisibility(context, properties) {
        let locationGroup = null;
        if (context.state.locationEditing != null) {
            locationGroup = context.state.model.locationGroups.find(
                (group: any) => group.id == context.state.locationEditing.locationGroup
            );
        } else if (context.state.location != null) {
            locationGroup = context.state.model.locationGroups.find(
                (group: any) => group.id == context.state.location.locationGroupId
            );
        }
        const locationGroupCode = locationGroup ? locationGroup.code : null;
        if (properties != null) {
            const extendedProperties = [...properties, { name: 'LocationGroupCode', numberValue: null, stringValue: locationGroupCode }];
            properties.forEach(function (property: any) {


                if (property.dependencyExpression != null) {
                    let expression: any = property.dependencyExpression.replace(/\[[a-zA-Z0-9_\s]*\]*/g, function (v: any) { return v.toUpperCase(); });
                    extendedProperties.forEach(function (subproperty: any) {
                        expression = expression.replace('[' + subproperty.name.toUpperCase() + ']', ['\'' + subproperty.stringValue + '\'', subproperty.numberValue].join(''));
                    });
                    try {
                        console.log(expression);
                        property['visible'] = new Expression(expression).Evaluate();
                    }
                    catch (error) {

                        console.error('An error occured with the NCalc expression : ' + property.dependencyExpression, error, extendedProperties);
                        property['visible'] = true;
                    }
                }
                else {
                    property['visible'] = true;
                }
            });
        }
        
    },
    async fetchLocationIsoLines(context) {
        if (context.state.location != null && (context.state.isoLinesFetchingBusy == null || context.state.isoLinesFetchingBusy != context.state.location.id)) {
            context.state.isoLinesFetchingBusy = context.state.location.id;
            if (context.state.locationIsoLines == null) {
                context.commit('SET_STARTINPROGRESS', null, { root: true });
                axios.get('/api/models/scenarios/locations/' + context.state.location.id + '/isolines').then(response => {
                    if (context.state.isoLinesFetchingBusy == context.state.location.id) {
                        response.data.features.forEach(function (feature: any) {
                            feature.properties['zIndex'] = 3
                            feature.properties['fillOpacity'] = 0
                            feature.properties['strokeOpacity'] = 0.5
                            feature.properties['strokeColor'] = '#0000CC'
                            feature.properties['strokeWeight'] = 5
                        });
                        context.commit('SET_LOCATIONISOLINES', response.data)
                    }
                    context.state.isoLinesFetchingBusy = context.state.location.id;
                    context.commit('SET_ENDINPROGRESS', null, { root: true });
                }).catch((error) => {
                    context.state.isoLinesFetchingBusy = null;
                    context.commit('SET_ENDINPROGRESS', error, { root: true });
                    
                })
            }
            
        }
    },
    async fetchIsoLine(context, payload) {
        context.commit('SET_STARTBUSY', null, { root: true });
        axios.get('/api/isoline/' + payload.isoLineSetId + '?longitude=' + context.state.location.lng + '&latitude=' + context.state.location.lat + '&minutes=' + payload.minutes + '&profile=' + payload.profile +  '&baseUrl=' + encodeURIComponent(payload.baseUrl) + '&parameters=' + encodeURIComponent(payload.parameters)).then(async response => {
            response.data.features.forEach(function (feature: any) {
                feature.properties['zIndex'] = 3
                feature.properties['fillOpacity'] = 0
                feature.properties['strokeOpacity'] = 0.5
                feature.properties['strokeColor'] = '#0000CC'
                feature.properties['strokeWeight'] = 5
            });
            context.commit('SET_LOCATIONISOLINES', response.data);
            context.commit('SET_ENDBUSY', null, { root: true });
        }).catch((error) => {
            context.commit('SET_ERROR', error, { root: true })
        })
    },
   
    async fetchCatchmentArea(context) {
        if (context.state.locationCatchmentArea?.catchmentAreaId != context.state.catchmentArea?.catchmentAreaId) {

            await context.commit('SET_LOCATIONCATCHMENTAREA', null);
            if (context.state.fetchCatchmentAreaBusy != null) {
                context.state.fetchCatchmentAreaBusy.cancel();
                context.state.fetchCatchmentAreaBusy = null;

            }
            if (context.state.catchmentArea != null) {
                (async () => {
                    while (context.state.fetchCatchmentAreaBusy != null)
                        await new Promise(resolve => setTimeout(resolve, 1000));
                    context.state.fetchCatchmentAreaBusy = axios.CancelToken.source();
                    if (context.state.location == null) {
                        context.state.fetchCatchmentAreaBusy = null;
                    }
                    else {
                        if (context.state.location.changeType != 2) {
                            // Only fetch the catchment area if the location is not removed.
                            context.commit('SET_STARTINPROGRESS', null, { root: true });
                            axios.get('/api/models/' + context.state.subModel.id + '/scenarios/' + context.state.scenario.id + '/locations/' + context.state.location.id + '/catchment-areas/' + context.state.catchmentArea.catchmentAreaId, {
                                cancelToken: context.state.fetchCatchmentAreaBusy.token
                            }).then(async (response) => {
                                if (response.data.featureCollection != null) response.data.featureCollection.features = filterPointTypeGeometries(response.data.featureCollection.features);
                                await context.commit('SET_LOCATIONCATCHMENTAREA', response.data);

                                context.state.fetchCatchmentAreaBusy = null;
                                context.commit('SET_ENDINPROGRESS', null, { root: true });
                            }).catch((error) => {
                                if (!axios.isCancel(error)) {
                                    context.commit('SET_ERROR', error, { root: true })
                                }
                                else {
                                    context.commit('SET_ENDINPROGRESS', null, { root: true });
                                    context.state.fetchCatchmentAreaBusy = null;
                                }
                            })
                        }
                        else {
                            context.state.fetchCatchmentAreaBusy = null;
                        }
                    }
                })();
            }
        }
    },
    async fetchArea(context, payload) {
        context.commit('SET_STARTINPROGRESS', null, { root: true });
        let url = '/api/models/' + context.state.model.id + '/areasets/' + payload.areaSetId + '/areas/';
        if (payload.arguments && payload.arguments.latitude) {
            url += payload.arguments.longitude + '/' + payload.arguments.latitude;
        }
        else {
            url += payload.areaCode
        }
        url += '/properties'
        axios.get(url).then(response => {
            if (response.data.areaCode == null) {
                context.commit('SET_AREA', null)
            }
            else {
                if (response.data.featureCollection != null) response.data.featureCollection = filterPointTypeGeometries(response.data.featureCollection.features);
                context.commit('SET_AREA', response.data)
            }
            context.commit('SET_ENDINPROGRESS', null, { root: true });
        }).catch((error) => {
            if (error.response.status != 404) {
                context.commit('SET_ERROR', error, { root: true });
            }
            else {
                context.commit('SET_ENDINPROGRESS', null, { root: true });
            }
        })
    },
    async fetchAreaWithLocations(context, payload) {
        context.commit('SET_STARTBUSY', null, { root: true });
        let url = '/api/models/' + context.state.subModel.id + '/scenarios/' + context.state.scenario.id + '/catchment-areas/' + context.state.catchmentArea.catchmentAreaId + '/areas/';
        if (payload.arguments && payload.arguments.latitude) {
            url += payload.arguments.longitude + '/' + payload.arguments.latitude;
        }
        else {
            url += payload.areaCode
        }
        url += '/properties'
        axios.get(url).then(response => {
            if (response.data.areaCode == null) {
                context.commit('SET_AREA', null)
            }
            else {
                if (response.data.featureCollection != null) response.data.featureCollection = filterPointTypeGeometries(response.data.featureCollection.features);
                context.commit('SET_AREA', response.data)
            }
            context.commit('SET_ENDBUSY', null, { root: true });
        }).catch((error) => {
            if (error.response.status != 404) {
                context.commit('SET_ERROR', error, { root: true });
            }
            else {

                context.commit('SET_ENDBUSY', null, { root: true });
            }
        })
    },
    async clearArea(context, payload) {
        context.commit('SET_STARTBUSY', null, { root: true });
        context.commit('SET_AREA', null);
        context.commit('SET_ENDBUSY', null, { root: true });
    },
    async editExistingLocation(context, payload) {
        const locationProperties: any = [];
        context.state.locationProperties.forEach(function (locationProperty: any) {
            if (locationProperty.type == 1) { //locatie en dus geen groep of cluster
                if (context.state.location.changeType == 1) {
                    //nieuwe vestiging die bewerkt wordt
                    if (locationProperty.autoCalculateType == null && locationProperty.propertyType != 4 && locationProperty.propertyType != 5 && locationProperty.propertyType != 6 && locationProperty.propertyType != 1 && locationProperty.propertyType != 3) {
                        locationProperties.push(Object.assign({}, locationProperty)); 
                    }
                }
                else {
                    //bestaande vestiging (changetype 0 of 3)
                    if (locationProperty.autoCalculateType == null && locationProperty.propertyType != 4 && locationProperty.propertyType != 5 && locationProperty.propertyType != 6 && locationProperty.propertyType != 2 && locationProperty.propertyType != 1) { // property type 4 = output, property type 2 = read only existing, 1 = readolny
                        locationProperties.push(Object.assign({}, locationProperty)); 
                    }
                }
            }
        });
        const newLocation = cloneLocation(context.state.location);
        newLocation.locationGroup = newLocation.locationGroupId;
        newLocation.locationCluster = newLocation.locationClusterId;
        const location = { ...newLocation, locationProperties: locationProperties };
        await axios.get('/api/models/' + context.state.model.id + '/location-clusters/near-by/5?latitude=' + location.lat + '&longitude=' + location.lng).then(response => {
            location['locationClusters'] = response.data;
        }).catch((error) => {
            context.commit('SET_ERROR', error, { root: true })
        })
        context.commit('SET_LOCATIONEDITING', location );
    },
    async fetchPropertiesChartData(context, payload) {
        if (payload != null && payload.locationProperty1 != null && payload.locationProperty2 != null) {
            context.commit('SET_STARTBUSY', null, { root: true });
            await axios.get('/api/models/scenarios/' + context.state.scenario.id + '/locations/' + context.state.location.id + '/property-values/' + payload.locationProperty1.id + '/' + payload.locationProperty2.id).then(response => {
                context.state.propertiesChart.data = response.data;
            }).catch((error) => {
                context.commit('SET_ERROR', error, { root: true })
            })
            context.commit('SET_ENDBUSY', null, { root: true });
        }
    },
    async showPropertiesChart(context) {
        context.commit('SET_STARTBUSY', null, { root: true });
        await axios.get('/api/models/' + context.state.model.id + '/location-properties').then(response => {
            context.commit('SET_PROPERTIESCHART', { properties: response.data, data: null });
            context.commit('SET_ENDBUSY', null, { root: true });
        }).catch((error) => {
            context.commit('SET_ERROR', error, { root: true })
        })
    },
    async editNewLocation(context, payload) {
        context.commit('SET_STARTBUSY', null, { root: true });
        await axios.get('/api/models/' + + context.state.model.id + '/new-location-properties').then(response => {
            response.data.forEach(function (scenario: any) {
                scenario['value'] = null;
            });
            const locationProperties: any = [];
            response.data.forEach(function (locationProperty: any) {
                if (locationProperty.autoCalculateType == null && locationProperty.propertyType != 4 && locationProperty.propertyType != 1 && locationProperty.propertyType != 3) {
                    if (locationProperty.defaultValue != null) {
                        if (locationProperty.dataType == 0) locationProperty.stringValue = locationProperty.defaultValue;
                        if (locationProperty.dataType > 0) locationProperty.numberValue = Number(locationProperty.defaultValue);
                    }
                    locationProperties.push(locationProperty)
                }
                if (locationProperty.choices != null) {
                    locationProperty.choices.forEach(function (choice: any) {
                        if (locationProperty.dataType > 0) choice.key = Number(choice.key);
                    })
                }
            });
            payload['locationProperties'] = locationProperties;
        }).catch((error) => {
            context.commit('SET_ERROR', error, { root: true })
        })
        await axios.get('/api/models/' + context.state.model.id + '/location-clusters/near-by/5?latitude=' + payload.latitude + '&longitude=' + payload.longitude).then(response => {
            payload['locationClusters'] = response.data;
        }).catch((error) => {
            context.commit('SET_ERROR', error, { root: true })
        })
        //If there is only 1 location group, set it.
        if (context.state.model.locationGroups.length == 1 || (context.state.model.locationGroupMandatory && context.state.model.locationGroups.length > 0)) {
            payload['locationGroup'] = context.state.model.locationGroups.find((x: any) => x.selectable)?.id; 
        }
        context.commit('SET_LOCATIONEDITING', payload);
        context.commit('SET_ENDBUSY', null, { root: true });
    },
    async saveLocationEditing(context, payload) {
        context.commit('SET_STARTBUSY', null, { root: true });
        if (payload.id) {
            await axios.put('api/models/' + context.state.model.id + '/scenarios/' + context.state.scenario.id + '/locations/' + context.state.location.id, context.state.locationEditing).then(async () => {
                context.commit('SET_LOCATIONEDITING', null);
                await context.dispatch('setLocationById', context.state.location.id);
                await context.dispatch('setScenarioById', context.state.scenario.id);
                context.commit('SET_ENDBUSY', null, { root: true });
            }).catch((error) => {
                context.commit('SET_ERROR', error, { root: true })
            })
        }
        else {

            // #region Create scenario when the new location is in the default scenario
            if (context.state.scenario.parentScenarioId == null) {
                await context.dispatch('saveScenarioEditing', { id: 0, name: payload.name, created: null, modified: null, isTop: false, competition: true, parentScenarioId: context.state.scenario.id });
            }
            // #endregion
            await axios.post('api/models/' + context.state.model.id + '/scenarios/' + context.state.scenario.id + '/locations', context.state.locationEditing).then(async () => {
                await context.dispatch('fetchLocations', context.state.scenario.id);
                await context.dispatch('fetchScenarios', true);
                context.commit('SET_LOCATIONEDITING', null);
                context.commit('SET_ENDBUSY', null, { root: true });
            }).catch((error) => {
                context.commit('SET_ERROR', error, { root: true })
            })
        }
    },
    async undoEditLocation(context, location) {
        context.commit('SET_STARTBUSY', null, { root: true });
        await axios.put('/api/models/scenarios/' + context.state.scenario.id + '/locations/' + location.id + '/undo-edit').then(async () => {
            if (context.state.location != null && context.state.location.id == location.id) await context.dispatch('setLocationById', location.id);
            await context.dispatch('setScenarioById', context.state.scenario.id);
            context.commit('SET_ENDBUSY', null, { root: true });
        }).catch((error) => {
            context.commit('SET_ERROR', error, { root: true })
        })
    },
    async closeLocation(context, location) {
        context.commit('SET_STARTBUSY', null, { root: true });
            await axios.delete('/api/models/scenarios/' + context.state.scenario.id + '/locations/' + location.id + '/close').then(async () => {
             await context.dispatch('fetchLocations', null);
             await context.dispatch('fetchScenarios', true);
            context.commit('SET_ENDBUSY', null, { root: true });
        }).catch((error) => {
            context.commit('SET_ERROR', error, { root: true })
        })
    },
    async undoCloseLocation(context, location) {
        context.commit('SET_STARTBUSY', null, { root: true });
        await axios.put('/api/models/scenarios/' + context.state.scenario.id + '/locations/' + location.id + '/undo-close').then(async () => {
            await context.dispatch('fetchLocations', null);
            await context.dispatch('fetchScenarios', true);
            context.commit('SET_ENDBUSY', null, { root: true });
        }).catch((error) => {
            context.commit('SET_ERROR', error, { root: true })
        })
    },
    async removeLocation(context, location) {
        context.commit('SET_STARTBUSY', null, { root: true });
        await axios.delete('/api/models/scenarios/' + context.state.scenario.id + '/locations/' + location.id).then(async () => {
            await context.dispatch('fetchLocations', null);
            await context.dispatch('fetchScenarios', true);
            context.commit('SET_ENDBUSY', null, { root: true });
        }).catch((error) => {
            context.commit('SET_ERROR', error, { root: true });
        })
    },
    async calculateScenario(context,scenario) {
        context.commit('SET_STARTBUSY', null, { root: true });
        await axios.put('/api/models/scenarios/' + scenario.id + '/start-processing').then(function () {
            context.dispatch('fetchScenarios', true)
            context.commit('SET_ENDBUSY', null, { root: true });
        }).catch((error) => {
            context.commit('SET_ERROR', error, { root: true });
        })
    },
    async calculateUnprocessedScenarios(context, model) {
        context.commit('SET_STARTBUSY', null, { root: true });
        await axios.put('/api/models/' + model.id + '/scenarios/start-processing-unprocessed').then(function () {
            context.dispatch('fetchScenarios', true)
            context.commit('SET_ENDBUSY', null, { root: true });
        }).catch((error) => {
            context.commit('SET_ERROR', error, { root: true });
        })
    },
    async editScenario(context, payload) {
        context.commit('SET_STARTBUSY', null, { root: true });
        context.commit('SET_SCENARIOEDITING', payload);
        context.commit('SET_ENDBUSY', null, { root: true });
    },
    async saveScenarioEditing(context, payload) {
        context.commit('SET_STARTBUSY', null, { root: true });
        const scenarioEditing = payload != null ? payload : context.state.scenarioEditing;
        if (scenarioEditing.id) {
            await axios.put('/api/models/' + context.state.model.id  + '/scenarios', scenarioEditing).then(function () {
                null;
            }).catch((error) => {
                context.commit('SET_ERROR', error, { root: true });
            })
        }
        else {
            scenarioEditing.created = new Date();
            scenarioEditing.modified = new Date();
            await axios.post('/api/models/' + context.state.model.id + '/scenarios', scenarioEditing).then(response => {
                scenarioEditing.id = response.data;
            }).catch((error) => {
                context.commit('SET_ERROR', error, { root: true });
            })
        }
        await context.dispatch('fetchScenarios');
        context.dispatch('setScenarioById', scenarioEditing.id);
        context.commit('SET_SCENARIOEDITING', null);
        context.commit('SET_ENDBUSY', null, { root: true });
    },
    async removeScenario(context,scenario) {
        context.commit('SET_STARTBUSY', null, { root: true });
        await axios.delete('/api/models/' + context.state.model.id + '/scenarios/' + scenario.id).then(async function () {
            context.state.scenario = null;
            await context.dispatch('fetchScenarios');
            context.commit('SET_ENDBUSY', null, { root: true });
        }).catch((error) => {
            context.commit('SET_ERROR', error, { root: true });
        })
    },
    async clearScenarioDifferences(context) {
        context.commit('SET_SCENARIODIFFERENCES', null);
    },
    async fetchScenarioDifferences(context) {
        context.commit('SET_STARTBUSY', null, { root: true });
        axios.get('/api/models/' + context.state.subModel.id + '/scenarios/' + context.state.scenario.id + '/differences?thresholdPercentage=0.001').then(response => {
            context.commit('SET_ENDBUSY', null, { root: true });
            const scenarioDifferences = response.data;
            //scenarioDifferences['locations'] = context.state.locations;
            context.commit('SET_SCENARIODIFFERENCES', scenarioDifferences);
        }).catch((error) => {
            context.commit('SET_ERROR', error, { root: true })
        })
    },
    async fetchScenarioParents(context) {
        context.commit('SET_STARTBUSY', null, { root: true });
        axios.get('/api/models/scenarios/' + context.state.scenario.id + '/parents').then(response => {
            context.commit('SET_ENDBUSY', null, { root: true });
            context.state.scenario["parents"] = response.data;
        }).catch((error) => {
            context.commit('SET_ERROR', error, { root: true })
        })
    },
};