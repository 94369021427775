
    import { Component, Vue, Prop,Watch } from 'vue-property-decorator';
    import { Scatter  } from 'vue-chartjs'
    
    @Component({
        components: {
            Scatter
        },
    })
    export default class PropertiesChart extends Vue {

        @Prop({ default: null }) public propertiesChartData: any;
        @Prop({ default: null }) public scenario: any;
        @Prop({ default: null }) public location: any;
        @Prop({ default: null }) public matchingColors: any;

        public locationProperty1: any = null;
        public locationProperty2: any = null;
        
        public datasets: any = [];
        public theModel: any = '';

        get filteredProperties() {
            if (this.propertiesChartData != null) {
                return this.propertiesChartData.properties.filter(function (locationProperty: any) {
                    return locationProperty.title != null && locationProperty.dataType != 0;
                });
            }
            else {
                return [];
            }
        }

        get chartData() {

            return {
                datasets: this.datasets
            };
        }

        get chartOptions() {
            return {
                responsive: true,
                plugins: {
                    datalabels: {
                        display: false,
                    },
                    legend: {
                        display: false,
                    }
                }
            }
        }
       

        

        @Watch('propertiesChartData.data')
        private onPropertiesChartDataChanged()
        {
            this.datasets = [];
            
           
            
            
            const self = this;
            if (this.propertiesChartData != null && this.propertiesChartData.data != null) {
                this.propertiesChartData.data.forEach(function (row: any) {
                    let color = "#aaaaaa";
                    if (self.datasets.length <= self.matchingColors.length) color = self.matchingColors[self.datasets.length];
                    self.datasets.push({ label: row.locationName, backgroundColor: color, pointRadius: 5, data: [{ x: row.numberValue1, y: row.numberValue2 }] });
                    
                })
            }
            
           
        }
       

        @Watch('propertiesChartData')
        @Watch('locationProperty1')
        @Watch('locationProperty2')
        private onLocationPropertyChanged() {
            this.$emit('locationPropertyChanged', { locationProperty1: this.locationProperty1, locationProperty2: this.locationProperty2 });
        }
    }
