
    import { Component, Vue, Watch } from 'vue-property-decorator';
    import { Getter, Mutation } from 'vuex-class';
    @Component({
        components: {
        },
    })
    export default class Error extends Vue {

        private modalShow = false;

        @Mutation('SET_ERROR') setError: any;
        @Getter('getError') getError!: any;
        @Getter('getDebug') debug: any;

        @Watch('getError')
        private getErrorChanged() {
            if (this.getError != null) this.modalShow = true; else this.modalShow = false;
        }
        
    }
