
    
    import { Action, Getter } from 'vuex-class';
    import { Component, Vue } from 'vue-property-decorator';

   
    const namespace = 'admin';

    @Component({
        components: {
        },
    })
    export default class AdminUsers extends Vue {

        
        
        @Getter('getModelUsers', { namespace }) getModelUsers: any;
        @Getter('getSelectedModel', { namespace }) getSelectedModel: any;
        @Action('syncLogins', { namespace }) syncLogins: any;
        @Action('setSelectedModel', { namespace }) setSelectedModel: any;
        @Action('saveSelectedModel', { namespace }) saveSelectedModel: any;


        get normalUsers() {
            if (this.getModelUsers == null) return null;
            return this.getModelUsers.filter(function (user: any) {
                return user.deletedFromAzureAd == false;
            });
            
        }

        get deletedUsers() {
            if (this.getModelUsers == null) return null;
            return this.getModelUsers.filter(function (user: any) {
                return user.deletedFromAzureAd == true;
            });
            
        }
       

        

    }
    
