
    
    import { Action, Getter } from 'vuex-class';
    import { Component, Vue, Watch } from 'vue-property-decorator';
    const namespace = 'admin';

    @Component({
        components: {
            
        },
    })
    export default class AdminMain extends Vue {

        @Getter('getUsers', { namespace }) getUsers: any;
        
        @Getter('getModels', { namespace }) getModels: any;
        @Getter('getUserModels', { namespace }) getUserModels: any;
        @Getter('getIsLoggedIn') getIsLoggedIn: any;
        @Action('init', { namespace }) init: any;
        @Getter('getSelectedUser', { namespace }) getSelectedUser: any;
        @Action('setSelectedUser', { namespace }) setSelectedUser: any;
        @Action('saveSelectedUser', { namespace }) saveSelectedUser: any;

        private leftDrawer= {
            width: window.innerWidth > 1200 ? 900 : window.innerWidth / 1.5,
            borderSize: 3
        };

        @Watch('$route', { immediate: true, deep: true })
        onUrlChange() {
            if (this.getIsLoggedIn) this.init();
        }


        @Watch('getIsLoggedIn')
        private onGetIsLoggedInChanged() {
            this.init();
        }

        private mounted() {
            const leftDrawerInterval = setInterval(() => {
                if (this.$refs.leftDrawer == null) return;
                const ld = ((this.$refs.leftDrawer as Vue).$el as HTMLElement);
                if (ld != null) {
                    this.setEvents(ld,this.leftDrawer);
                    clearInterval(leftDrawerInterval)
                }
            }, 50)
            //if (this.getIsLoggedIn) this.init();
        }

        

        
        private setEvents(el: any, struct: any) {


            const i = el.querySelector(
                ".v-navigation-drawer__border"
            );

            i.style.width = struct.borderSize + "px";
            i.style.cursor = "ew-resize";
            i.style.backgroundColor = "rgb(245,245,245)";


            const minSize = struct.borderSize;
            const drawerBorder = el.querySelector(".v-navigation-drawer__border");
            const direction = el.classList.contains("v-navigation-drawer--right")?"right":"left";

            function resize(e: any) {
                document.body.style.cursor = "ew-resize";
                const f =
                    direction === "right"
                        ? document.body.scrollWidth - e.clientX
                        : e.clientX;
                el.style.width = f + "px";
            }

            drawerBorder.addEventListener(
                "mousedown",
                (e: any) => {
                    if (e.offsetX < minSize) {
                        el.style.transition = "initial";
                        document.addEventListener("mousemove", resize, false);
                        
                    }
                },
                false
            );

            

            document.addEventListener(
                "mouseup",
                () => {
                    el.style.transition = "";
                    struct.width = el.style.width;
                    document.body.style.cursor = "";
                    document.removeEventListener("mousemove", resize, false);
                    
                },
                false
            );

            
        }
    }
    
