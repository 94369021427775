import * as msal from "@azure/msal-browser";
import Vue, { PluginObject, VueConstructor } from "vue";

declare module "vue/types/vue" {
    interface Vue {
        $msal: MsalPlugin;
    }
}

export interface MsalPluginOptions {
    clientId: string;
    loginAuthority: string;
    passwordAuthority: string;
    knownAuthority: string;
}

let msalInstance: msal.PublicClientApplication;

export let msalPluginInstance: MsalPlugin;

export class MsalPlugin implements PluginObject<MsalPluginOptions> {

    constructor(scope: string) {
        this._scope = scope;
    }

    private _scope: any;
    private pluginOptions: MsalPluginOptions = {
        clientId: "",
        loginAuthority: "",
        passwordAuthority: "",
        knownAuthority: ""
    };

    public isAuthenticated = false;

    public install(vue: VueConstructor<Vue>, options?: MsalPluginOptions): void {
        if (!options) {
            throw new Error("MsalPluginOptions must be specified");
        }
        this.pluginOptions = options;
        this.initialize(options);
        msalPluginInstance = this;
        vue.prototype.$msal = Vue.observable(msalPluginInstance);
    }

    private async initialize(options: MsalPluginOptions) {
        const msalConfig: msal.Configuration = {
            auth: {
                clientId: options.clientId,
                authority: options.loginAuthority,
                knownAuthorities: [options.knownAuthority]
            }
        };
        msalInstance = new msal.PublicClientApplication(msalConfig);
        msalInstance.handleRedirectPromise().then((authResult: any) => {
            this.isAuthenticated = this.getIsAuthenticated();
            
        }).catch((err: any) => {
            if (err.errorMessage && err.errorMessage.indexOf("AADB2C90118") > -1) {
                msalInstance.loginRedirect({
                    scopes: ["openid", "profile", "offline_access"],
                    authority: this.pluginOptions.passwordAuthority
                });
            } else {
                if (err.errorCode == 'user_cancelled') {
                    //User cancelled the login
                }
                this.isAuthenticated = false;
            }
        });
        this.isAuthenticated = this.getIsAuthenticated();
    }

    public account() {
        return msalInstance.getAllAccounts()[0];
    }

    public async signIn() {
        const loginRequest: msal.PopupRequest = {
            scopes: ["openid", "profile", "offline_access",this._scope],
        };
        await msalInstance.loginRedirect(loginRequest);
    }

    public async signOut() {
        await msalInstance.logout();
        this.isAuthenticated = false;
    }

    public async acquireToken() {
        const request = {
            account: msalInstance.getAllAccounts()[0],
            scopes: [this._scope]
        };
        try {
            const response = await msalInstance.acquireTokenSilent(request);
            return response.accessToken;
        } catch (error) {
            if (error instanceof msal.InteractionRequiredAuthError) {
                msalInstance.acquireTokenRedirect(request)
            }
            return false;
        }
    }

    private getIsAuthenticated(): boolean {
        const accounts: msal.AccountInfo[] = msalInstance.getAllAccounts();
        return accounts && accounts.length > 0;
    }
}