
    import { Component, Vue } from 'vue-property-decorator';
    import { Getter } from 'vuex-class';
    
    const namespace = 'main';
    @Component({
        components: {
        },
    })
    export default class ModelLogo extends Vue {
        @Getter('getModel', { namespace }) getModel: any;
    }
