import Vue from 'vue';
import Vuetify, {
    VInput,
    VAlert,
    VApp,
    VNavigationDrawer,
    VFooter,
    VList,
    VBtn,
    VIcon,
    VToolbar,
    VDataTable,
    VProgressLinear,
    VCol,
    VTooltip,
    VListItem,
    VListItemAction,
    VRow,
    VContent,
    VToolbarTitle,
    VContainer,
    VListItemContent,
    VListItemTitle,
    VAppBar,
    VAppBarNavIcon,
    VTreeview,
    VSelect,
    VDialog,
    VCard,
    VCardTitle,
    VOverlay,
    VProgressCircular,
    VCardText,
    VSpacer,
    VTextField,
    VAutocomplete,
    VChip,
    VExpansionPanel,
    VExpansionPanels,
    VExpansionPanelContent,
    VExpansionPanelHeader,
    VCardActions,
    VMenu,
    VRadioGroup,
    VRadio,
    VCardSubtitle,
    VSwitch,
    VImg,
    VSimpleTable,
    VLayout,
    VFlex,
    VSlider,
    VListItemIcon,
    VListItemGroup,
    VForm,
    VCheckbox,
    VSubheader,
    VFileInput,
    VDivider,
    VListItemAvatar,
    VCombobox,
    VTextarea,
} from 'vuetify/lib';

// vue-cli a-la-carte installation
Vue.use(Vuetify, {
    components: {
        VInput,
        VAlert,
        VApp,
        VNavigationDrawer,
        VFooter,
        VList,
        VBtn,
        VIcon,
        VToolbar,
        VDataTable,
        VProgressLinear,
        VCol,
        VTooltip,
        VListItem,
        VListItemAction,
        VRow,
        VContent,
        VToolbarTitle,
        VContainer,
        VListItemContent,
        VListItemTitle,
        VAppBar,
        VAppBarNavIcon,
        VTreeview,
        VSelect,
        VDialog,
        VCard,
        VCardTitle,
        VOverlay,
        VProgressCircular,
        VCardText,
        VSpacer,
        VTextField,
        VAutocomplete,
        VChip,
        VExpansionPanel,
        VExpansionPanels,
        VExpansionPanelContent,
        VExpansionPanelHeader,
        VMenu,
        VCardActions,
        VRadioGroup,
        VRadio,
        VCardSubtitle,
        VSwitch,
        VImg,
        VSimpleTable,
        VLayout,
        VFlex,
        VSlider,
        VDivider,
        VListItemIcon,
        VListItemGroup,
        VForm,
        VCheckbox,
        VSubheader,
        VFileInput,
        VListItemAvatar,
        VCombobox,
        VTextarea,
    },
});

const opts = {
    theme: {
        themes: {
            light: {
                primary: '#009cbb',
                secondary: '#424242',
                accent: '#82B1FF',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
            },
            
        },
    },
};

export default new Vuetify(opts);
