export default (value: any, locale: any, currency: any) => {
    if (typeof value !== "number") {
        return value;
    }
    const formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency == null?"EUR":currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });
    return formatter.format(value);
};
