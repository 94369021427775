
    import { Component, Vue, Prop } from 'vue-property-decorator';
    
    @Component
    export default class DriversChart extends Vue {
        @Prop({ default: null }) public locationDrivers: any;
        @Prop({ default: null }) public location: any;

        get filteredLocationDrivers() {
            const widthPx = 100;
            const halfWidthPx = widthPx / 2;
            return this.locationDrivers.filter(function (locationDriver: any) {
                const maxAboveAvg = locationDriver.maximum - locationDriver.average;
                const minBelowAvg = locationDriver.average - locationDriver.minimum;
                const width = Math.max(maxAboveAvg, minBelowAvg) * 2;
                const ratio = widthPx / width;
                const avgPos = width / 2 * ratio;
                const minPos = halfWidthPx - (minBelowAvg * ratio);
                const maxPos = (maxAboveAvg * ratio) + halfWidthPx;
                const valPos = halfWidthPx - ((locationDriver.average - locationDriver.value) * ratio);
                locationDriver["avgPos"] = avgPos;
                locationDriver["minPos"] = minPos;
                locationDriver["maxPos"] = maxPos;
                locationDriver["valPos"] = valPos;
                locationDriver["width"] = locationDriver.weight;
                return locationDriver;
            });
        }
    }
