
    import { Component, Vue, Watch } from 'vue-property-decorator';
    import { Action } from 'vuex-class';
    // @ts-ignore
    import { MsalPlugin } from '@/plugins/msal';
    import axios from 'axios';

    
   

      
            
     

    

    @Component({
        components: {
        },
    })
    export default class LoginMenu extends Vue {

        @Action('loggedIn') private loggedIn: any;
      
        public get isAuthenticated(): boolean {
            if (!this.loaded) return false;
            return this.$msal.isAuthenticated;
        }
        public async signIn() {
            await this.$msal.signIn();
        }
        public async signOut() {
            await this.$msal.signOut();
        }
        public get account() {
            return this.$msal.account();
        }

        private loaded: any = false;

        @Watch('isAuthenticated')
        private onIsAuthenticated(value: any, oldValue: any) {
            if (value == true && oldValue == false) this.loggedIn(this.$msal.account());
        }
        private mounted() {
            const _self = this;
            axios.get('/api/system/msal-configuration').then(async response => {
                Vue.use(new MsalPlugin(response.data.scope), response.data.options);
                if (_self.$msal.isAuthenticated) _self.loggedIn(_self.$msal.account());
                if (!_self.$msal.isAuthenticated) _self.signIn();
                _self.loaded = true;

            }).catch(() => {
                
            })
        }
        

    }
