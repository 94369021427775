
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

    @Component
    export default class Log extends Vue {

        

        private eventType: any[] = ["2", "4", "8"];
        private source: any = '';

        private getSources() {
            var sources: any = [''];
            sources.push('TheBigDataCompany.IRIS.Web.Backend');
            sources.push('TheBigDataCompany.IRIS.Processing.Scenarios');
            sources.push('TheBigDataCompany.IRIS.Processing.IsoLines');
            return sources;
        }

        @Watch('eventType')
        @Watch('source')
        private onEventTypeChanged() {
            let eventType = 0;
            if (this.eventType) {
                this.eventType.forEach(function (singleValue: any) { 
                    
                    eventType = eventType | singleValue; 
                
                });
            }
            this.$emit("fetchLog", { eventType: eventType, source: this.source });
        }
        
        @Prop({ default: null }) public data: any;

        @Watch('data')
        private onDataChanged(value: any, oldValue: any)
        {
            if (oldValue == null && value != null) {
                this.eventType = ["2", "4", "8"];
                this.source = '';
            }
        }
        

        get headers(): any {
            const h = [
                {
                    text: this.$t("message.dateAndTime"),
                    align: 'start',
                    sortable: false,
                    value: 'dateTimeUtc',
                },
                {
                    text: this.$t("message.user"),
                    align: 'start',
                    sortable: false,
                    value: 'user',
                },
                {
                    text: this.$t("message.source"),
                    align: 'start',
                    sortable: false,
                    value: 'source',
                },
                {
                    text: this.$t("message.eventType"),
                    align: 'start',
                    sortable: false,
                    value: 'eventType',
                },
                {
                    text: this.$t("message.message"),
                    align: 'start',
                    sortable: false,
                    value: 'message',
                }
                
            ];
            
            return h;
        }
    }
