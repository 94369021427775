
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import PropertyValue from '@/components/main/PropertyValue.vue'

    @Component({
        components: {
            PropertyValue,
        },
    })
    export default class AreaInforamtion extends Vue {

        @Prop({ default: null }) public model: any;
        @Prop({ default: null }) public area: any;
        @Prop({ default: null }) public scenario: any;
        
        private setLocation(location: any) {
            this.$emit('locationChanged', location);
        }
    }
