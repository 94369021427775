
    import { Component, Vue, Prop } from 'vue-property-decorator';
    
    @Component({
        components: {
        },
    })
    export default class ScenarioStatus extends Vue {
        private modalShow = false;

        @Prop({ default: 0 }) public status: any;
        @Prop({ default: true }) public scenario: any;
        @Prop({ default: null }) public changes: any;
        @Prop({ default: null }) public on: any;
        @Prop({ default: null }) public tooltip: any;

        get statusTooltip(): any {
            if (this.status > 0 && this.status < 9) return this.$t("message.scenarioStatusProcessing") + ' (' + (this.status * 11) + '%)';
            if (this.status == 0) return this.$t("message.scenarioStatusEdited");
            if (this.status == 9) return this.$t("message.scenarioStatusCompleted");
            if (this.status == 10) return this.$t("message.scenarioStatusCompletedWithErrors");
            return 'Error';
        }
    }
