import { ActionTree } from 'vuex';
import axios from 'axios';
import { AdminState } from './types';
import { RootState } from '../types';
import Vue from 'vue'
import { router } from "../../main";

export const actions: ActionTree<AdminState, RootState> = {

    async init(context) {
        context.dispatch('fetchUsers');
        context.dispatch('fetchModels');
        context.dispatch('fetchLogins');
        context.commit('SET_DATABASESTATUS', 2, { root: true });
        context.commit('SET_DEBUG', true, { root: true });
    },

    async fetchUsers(context) {
        context.commit('SET_STARTBUSY', null, { root: true });
        axios.get('/api/admin/users').then(response => {
            context.commit('SET_ENDBUSY', null, { root: true });
            context.commit('SET_USERS', response.data);
            context.dispatch('setSelectedUser', null);
        }).catch((error) => {
            context.commit('SET_ERROR', error, { root: true })
        })
    },

    async fetchScenarioStatus(context) {
        context.commit('SET_STARTBUSY', null, { root: true });
        axios.get('/api/admin/scenarioStatus').then(response => {
            context.commit('SET_ENDBUSY', null, { root: true });
            context.commit('SET_SCENARIOSTATUS', response.data);
            console.log(response.data);
        }).catch((error) => {
            context.commit('SET_ERROR', error, { root: true })
        })
    },

    async fetchLogins(context) {
        context.commit('SET_STARTBUSY', null, { root: true });
        axios.get('/api/admin/logins').then(response => {
            context.commit('SET_ENDBUSY', null, { root: true });
            context.commit('SET_LOGINS', response.data);
           
        }).catch((error) => {
            context.commit('SET_ERROR', error, { root: true })
        })
    },

    async syncLogins(context) {
        context.commit('SET_STARTBUSY', null, { root: true });
        await axios.put('/api/admin/logins').then(async () => {
            context.dispatch('fetchUsers', null);
            if (context.state.selectedModel != null) {
                context.dispatch('fetchModelUsers', context.state.selectedModel.id);
            }
            context.commit('SET_ENDBUSY', null, { root: true });

        }).catch((error) => {
            context.commit('SET_ERROR', error, { root: true })
        })
    },
    async deleteLogin(context, id) {
        context.commit('SET_STARTBUSY', null, { root: true });
        await axios.delete('/api/admin/logins/' + id).then(async () => {
            context.dispatch('fetchLogins', null);
            context.commit('SET_ENDBUSY', null, { root: true });

        }).catch((error) => {
            context.commit('SET_ERROR', error, { root: true })
        })
    },

    async addLogin(context) {
        context.commit('SET_STARTBUSY', null, { root: true });
        await axios.post('/api/admin/logins/',context.state.loginEditing).then(response => {
            context.commit('SET_LOGINEDITING', response.data);
            context.dispatch('fetchLogins', null);
            context.commit('SET_ENDBUSY', null, { root: true });
        }).catch((error) => {
            context.commit('SET_ERROR', error, { root: true })
        })
    },


    async newLogin(context) {
        context.commit('SET_LOGINEDITING', {
            displayName: '',
            email: '',
        });
    },



    async fetchModels(context) {
        context.commit('SET_STARTBUSY', null, { root: true });
        axios.get('/api/admin/models').then(response => {
            context.commit('SET_ENDBUSY', null, { root: true });
            context.commit('SET_MODELS', response.data);
            context.dispatch('setSelectedModel', null);
        }).catch((error) => {
            context.commit('SET_ERROR', error, { root: true })
        })
    },

    async setSelectedUser(context, selectedUser) {
        context.commit('SET_SELECTEDUSER', selectedUser);
        if (selectedUser == null) {
            context.commit('SET_USERMODELS', null);
        } else {
            context.dispatch('fetchUserModels', selectedUser.id);
        }
    },

    async setSelectedModel(context, selectedModel) {
        context.commit('SET_SELECTEDMODEL', selectedModel);
        if (selectedModel == null) {
            context.commit('SET_MODELUSERS', null);
        } else {
            context.dispatch('fetchModelUsers', selectedModel.id);
        }
    },

    async fetchUserModels(context, userId) {
        context.commit('SET_STARTBUSY', null, { root: true });
        axios.get('/api/admin/models/' + userId).then(response => {
            context.commit('SET_USERMODELS', response.data);
            context.commit('SET_ENDBUSY', null, { root: true });
            
            
        }).catch((error) => {
            context.commit('SET_ERROR', error, { root: true })
        })
    },

    async fetchModelUsers(context, modelId) {
        context.commit('SET_STARTBUSY', null, { root: true });
        axios.get('/api/admin/users/' + modelId).then(response => {
            context.commit('SET_ENDBUSY', null, { root: true });
            context.commit('SET_MODELUSERS', response.data);
        }).catch((error) => {
            context.commit('SET_ERROR', error, { root: true })
        })
    },

    async saveSelectedModel(context) {
        await axios.put('/api/admin/models', { model: context.state.selectedModel, users: context.state.modelUsers }).then(async () => {
            context.dispatch('fetchModelUsers', context.state.selectedModel.id);
            context.dispatch('fetchModels');
            context.commit('SET_ENDBUSY', null, { root: true });
        }).catch((error) => {
            context.commit('SET_ERROR', error, { root: true })
        })
        
    },

    async saveSelectedUser(context) {
        await axios.put('/api/admin/users', { user: context.state.selectedUser, models: context.state.userModels }).then(async () => {
            context.dispatch('fetchUserModels', context.state.selectedUser.id);
            context.dispatch('fetchUsers');
            context.commit('SET_ENDBUSY', null, { root: true });
        }).catch((error) => {
            context.commit('SET_ERROR', error, { root: true })
        })
    },


};
