import { render, staticRenderFns } from "./PropertyValue.vue?vue&type=template&id=a882fe5c&"
import script from "./PropertyValue.vue?vue&type=script&lang=ts&"
export * from "./PropertyValue.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./PropertyValue.vue?vue&type=custom&index=0&blockType=span&v-if=dataType%20%3D%3D%204"
if (typeof block0 === 'function') block0(component)

export default component.exports