
    import { Component, Vue } from 'vue-property-decorator';
    import { Action, Getter, Mutation } from 'vuex-class';
    import ScenarioStatus from '@/components/main/ScenarioStatus.vue';
    import ScenarioTree from '@/components/main/ScenarioTree.vue';
    import { localeIncludes } from 'locale-includes';

    const namespace = 'main';
    @Component({
        components: {
            ScenarioStatus,
            ScenarioTree
        },
    })
    export default class ModelMenu extends Vue {

        @Action('setModel', { namespace }) setModel: any;
        @Getter('getScenarios', { namespace }) getScenarios: any;
        @Getter('getLocations', { namespace }) getLocations: any;
        @Getter('getSubModels', { namespace }) getSubModels: any;
        @Getter('getScenario', { namespace }) getScenario: any;
        @Action('setScenario', { namespace }) setScenario: any;
        @Getter('getSubModel', { namespace }) getSubModel: any;
        @Action('setSubModel', { namespace }) setSubModel: any;
        @Getter('getModels', { namespace }) getModels: any;
        @Getter('getModel', { namespace }) getModel: any;
        @Action('downloadFile') downloadFile: any;
        @Action('editScenario', { namespace }) editScenario: any;
        @Action('calculateUnprocessedScenarios', { namespace }) calculateUnprocessedScenarios: any;

        @Getter('getStandByFilterScenarios', { namespace }) getStandByFilterScenarios: any;
        @Action('filterScenarios', { namespace }) filterScenarios: any;
        @Getter('getFilterScenarios', { namespace }) getFilterScenarios: any;

        @Getter('getFilteredScenarios', { namespace }) getFilteredScenarios: any;
        @Mutation('SET_STANDBYFILTERSCENARIOS', { namespace }) setStandByFilterScenarios: any;


        public get isAuthenticated(): boolean {
            if (this.$msal == null) return true;
            return this.$msal.isAuthenticated;
        }

        private setFilterScenarios(value: any) {
            this.filterScenarios(value);
        }

        get standByFilterScenarios(): any {
            return this.getStandByFilterScenarios;
        }
        set standByFilterScenarios(value: any) {
            this.setStandByFilterScenarios(value);
        }

        get models(): any {
            return this.getModels;
        }
        get model(): any {
            const model = this.getModel;
            if (model != null) this.$i18n.locale = model.language;
            return model;
        }
        set model(value: any) {
            this.setModel(value);
        }
        get scenarios(): any {
            return this.getScenarios;
        }
        get scenario(): any {
            return this.getScenario;
        }
        set scenario(value: any) {
            this.setScenario(value);
        }
        get subModels(): any {
            return this.getSubModels;
        }
        get subModel(): any {
            return this.getSubModel;
        }
        set subModel(value: any) {
            this.setSubModel(value);
        }

        getScenariosTree(parentNode: any, parentScenarioId: any, level: any) {

            level++;
            if (parentNode == null) {
                parentNode = [];
            }
            let nodesArray: any = [];
            this.scenarios.forEach((scenario: any) => {
                if (scenario.parentScenarioId == parentScenarioId && scenario.isTop) {
                    var node = {
                        id: scenario.id,
                        name: scenario.name,
                        scenario: scenario,
                        level: level,
                        children: this.getScenariosTree(this, scenario.id,level + 1)
                    }
                    if (node.children.length > 0 || localeIncludes(node.name, this.getFilterScenarios, { usage: "search", sensitivity: "base" })) nodesArray.push(node);
                }
            });
            this.scenarios.forEach((scenario: any) => {
                if (scenario.parentScenarioId == parentScenarioId && !scenario.isTop) {
                    var node = {
                        id: scenario.id,
                        name: scenario.name,
                        scenario: scenario,
                        level: level,
                        children: this.getScenariosTree(this, scenario.id, level + 1)
                    }
                    if (node.children.length > 0 || localeIncludes(node.name, this.getFilterScenarios, { usage: "search", sensitivity: "base" })) nodesArray.push(node);
                }
            });
            return nodesArray;

            
        }

        get changedLocations() {
            if (this.getLocations != null) {
                return this.getLocations.filter(function (location: any) {
                    return location.changeType != 0;
                });
            }
            else {
                return [];
            }
        }

        get languages() {
            return [
                { key: 'nl', text: this.$t('message.dutchFormat'), helpUrl: 'https://thebigdatacompany.sharepoint.com/:b:/s/BrancheOmzetModel/Eeg_BD_zaH1ArWdD59Z7awwBnLIgxFJtZ8wyAeDWzC-zHQ?e=4PQV0g' },
                { key: 'en', text: this.$t('message.englishFormat'), helpUrl: 'https://thebigdatacompany.sharepoint.com/:b:/s/BrancheOmzetModel/EQvrt3v6utZFsXKDDoMdbkkBpuSD3R13bVYzZduQIl2xdQ?e=tIkHF2' }
            ]
        }

        get helpUrl() {
            const _self = this;
            let helpUrl = '';
            this.languages.forEach(function (language) {
                if (language.key == _self.$i18n.locale) helpUrl = language.helpUrl;
            })
            return helpUrl;
        }

    }
