
    import { Component, Vue, Prop } from 'vue-property-decorator';
    
    import GoogleMap from '@/components/main/GoogleMap.vue'
    import Properties from '@/components/main/Properties.vue'
    import DriversChart from '@/components/main/DriversChart.vue';
    import MatchingLocationsOutputChart from '@/components/main/MatchingLocationsOutputChart.vue';
    import MatchingLocationsRadarChart from '@/components/main/MatchingLocationsRadarChart.vue';
    import PropertyValue from '@/components/main/PropertyValue.vue'
    
    @Component({
        components: {
            GoogleMap,
            Properties,
            PropertyValue,
            DriversChart,
            MatchingLocationsOutputChart,
            MatchingLocationsRadarChart,
        },
    })
    export default class LocationReport extends Vue {

        @Prop({ default: false }) public disabled: any;
        @Prop({ default: null }) public model: any;
        @Prop({ default: null }) public subModel: any;
        @Prop({ default: null }) public location: any;
        @Prop({ default: null }) public locations: any;
        @Prop({ default: null }) public scenario: any;
        @Prop({ default: null }) public locationProperties: any;
        @Prop({ default: null }) public locationGroupProperties: any;
        @Prop({ default: null }) public locationCluster: any;
        @Prop({ default: null }) public locationCatchmentArea: any;
        @Prop({ default: null }) public catchmentArea: any;
        @Prop({ default: null }) public show: any;
        @Prop({ default: null }) public matchingLocations: any;
        @Prop({ default: null }) public matchingScores: any;
        @Prop({ default: null }) public matchingColors: any;
        @Prop({ default: null }) public locationDrivers: any;
        @Prop({ default: null }) public environment: any;

        private refresh = 1;

        private startBusy() {
            this.$emit('startBusy');
        }

        private endBusy() {
            this.$emit('endBusy');
        }

        private get localShow() {
            return this.show;
        }

        private async close() {
            this.localShow = false;
        }


        private set localShow(value: any) {
            this.$emit("showChanged", value);
            this.refresh++;
        }
        
        private get totalOutput() {
            let value = 0;
            this.locationCatchmentArea.areas.forEach(function (area: any) {
                value += area.output;
            });
            return value;
        }
        private get totalOutputDefault() {
            let value = 0;
            this.locationCatchmentArea.areas.forEach(function (area: any) {
                value += area.outputDefaultScenario;
            });
            return value;
        }

        private selectViewLocation(value: any) {
            return value.viewLocation == 0;
        }
        private selectViewLocationOutput(value: any) {
            
            return value.viewLocation == 1;
        }
       
        private filterItems(arr: [], sectionName: any) {
            if (arr == null) return null;
            return arr.filter((el : any) => el.sectionName == sectionName);
        }

        public get account() {
            return this.$msal.account();
        }

    }
