
    import { Component, Vue, Prop } from 'vue-property-decorator';
    
    @Component({
        components: {
        },
    })
    export default class AreaScores extends Vue {
        @Prop({ default: null }) public area: any;
        @Prop({ default: null }) public model: any;
    }
