
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import GoogleMap from '@/components/main/GoogleMap.vue'
    import ModelPropertyValue from '@/components/main/ModelPropertyValue.vue'
    @Component({
        components: {
            ModelPropertyValue,
            GoogleMap
            
        },
    })
    export default class ScenarioReport extends Vue {
        private refresh = 1;
        @Prop({ default: false }) public disabled: any;
        @Prop({ default: null }) public model: any;
        @Prop({ default: null }) public locations: any;
        @Prop({ default: null }) public scenario: any;
        @Prop({ default: null }) public scenarioDifferences: any;
        @Prop({ default: null }) public show: any;
        @Prop({ default: null }) public environment: any;

        get changedLocations() {
            return this.locations.filter(function (location: any) {
                return location.changeType != 0;
            });
        }

        private async close() {
            this.localShow = false;
        }


        private calculateTotelDifference(locations : any) {
            let sumOutputDifference = locations.reduce((sum : any, location : any) => {
                return sum + location.outputDifference;
            }, 0);
            return sumOutputDifference;
        }
        

    

        get differentLocations() {
            const _self = this;
            return this.locations.filter(function (location: any) {
                let returnValue = false;
                if (_self.scenarioDifferences) {
                    _self.scenarioDifferences.locations.forEach(function (differentLocation: any) {
                        if (differentLocation.locationId == location.id) {
                            returnValue = true;
                        }
                    });
                }
                return returnValue;
            });
        }

        get centerLocation() {
            return this.changedLocations[0];
        }

        private startBusy() {
            this.$emit('startBusy');
        }

        private endBusy() {
            this.$emit('endBusy');
        }

        private get localShow() {
            return this.show;
        }

        private set localShow(value: any) {
            this.$emit("showChanged", value);
        }

        public get account() {
            return this.$msal.account();
        }
    }
