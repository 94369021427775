
    
    import { Action, Getter } from 'vuex-class';
    import { Component, Vue } from 'vue-property-decorator';

   
    const namespace = 'admin';

    @Component({
        components: {
        },
    })
    export default class AdminUsers extends Vue {

        
        
        @Getter('getUserModels', { namespace }) getUserModels: any;
        @Getter('getSelectedUser', { namespace }) getSelectedUser: any;
        @Action('setSelectedUser', { namespace }) setSelectedUser: any;
        @Action('saveSelectedUser', { namespace }) saveSelectedUser: any;

        

       

        

    }
    
