
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import { Radar } from 'vue-chartjs'
    
    @Component({
        components: {
            Radar
        },
    })
    export default class MatchingLocationsRadarChart extends Vue {
        @Prop({ default: null }) public matchingScores: any;
        @Prop({ default: null }) public location: any;
        @Prop({ default: null }) public matchingColors: any;

        private setLocation(location: any) {
            this.$emit('locationChanged', location);
        } 

        get chartData() {
            const self = this;
            if (self.matchingScores != null) {
                const datasets: any = [];
                const labels: any = [];
                self.matchingScores.slice(0, 6).forEach(function (output: any) {
                    let color = "#aaaaaa";
                    if (datasets.length <= self.matchingColors.length) color = self.matchingColors[datasets.length];

                    const dataset = { label: output.name + ' ' + Math.round(output.score * 1000) / 10 + "%", pointBackgroundColor: color, fill: true, borderColor: color, pointBorderColor: 'white', pointBorderWidth: 1, pointRadius: 5, data: [0], borderWidth: 3 };
                    datasets.push(dataset)
                    dataset.data = [];
                   
                    output.normalizedScores.forEach(function (normalizedScore: any) {
                        if (datasets.length == 1) {
                            labels.push(normalizedScore.title.split('\\n'));
                        }
                        dataset.data.push((Math.round(normalizedScore.score * 100) / 100));
                    })
                });
            
                return {
                    labels: labels,
                    datasets: datasets
                }
            
            }
            else
            {
                return null;
            }
        }

        get chartOptions() {
            return {
                responsive: true,
                maintainAspectRatio: false,
                
                indexAxis: 'y',
                animation: {
                    duration: 0
                },
                
                scale: {
                    ticks: {
                        maxTicksLimit: 10,
                        stepSize: 0.2, // Adjust the step between ticks
                        suggestedMax: 1, // Set the maximum value on the scale
                        suggestedMin: 0, // Set the minimum value on the scale
                        beginAtZero: true,
                        color: '#ff0000',
                        backdropColor: '#00ff00'

                    },

                    
                },
                scales: {
                    r: {
                        pointLabels: {
                            font: {
                                size: '14px'
                                
                            }
                        },
                        ticks: {
                            display: false,
                            
                            
                        },
                        grid: {
                            color: ['#cccccc']
                        }
                        
                    }
                },

                plugins: {
                    
                    legend: {
                        display: false,
                        position: 'bottom',
                        align:'center',
                        labels: {
                            
                            padding: 15,
                            usePointStyle: true,
                        },
                        
                    },
                    datalabels: {
                        display: false,
                        color: '#FFFFFF'
                    }
                }
            }
        }

    }
