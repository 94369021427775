
    import { Component, Vue, Prop } from 'vue-property-decorator';
    
    @Component
    export default class LocationScores extends Vue {

        @Prop({ default: null }) public model: any;
        @Prop({ default: null }) public locationScores: any;

        get totalScore() {
            let score = 1;
            if (this.locationScores.length == 0) return 0;
            this.locationScores.forEach(function (item: any) {
                score *= item.score;
            });
            
            return score;
        }
    }
