export default (value: any, digits: any,locale: any) => {
    if (typeof value !== "number") {
        return value;
    }
    
    const formatter = new Intl.NumberFormat(locale, {
        
        minimumFractionDigits: digits,
        maximumFractionDigits: digits

    });
    return formatter.format(value);
};
