
    import { Component, Vue, Prop } from 'vue-property-decorator';

    @Component({
        components: {

        },
    })
    export default class PropertyValue extends Vue {
        @Prop({ default: null }) public stringValue: any;
        @Prop({ default: null }) public oldStringValue: any;
        @Prop({ default: null }) public description: any;
        @Prop({ default: null }) public numberValue: any;
        @Prop({ default: null }) public oldNumberValue: any;
        @Prop({ default: null }) public dataType: any;
        @Prop({ default: 2 }) public roundingDigits: any;
        @Prop({ default: null }) public autoCalculateType: any;
        @Prop({ default: false }) public output: any;
        @Prop({ default: false }) public onlyCurrentValue: any;
        @Prop({ default: null }) public choices: any;
        @Prop({ default: 'EUR' }) public currency: any;

        getChoice(choices: any, value: any) {
            if (choices) {
                let choice = null;
                choices.forEach(function (item: any) {
                    if (item.key == value) choice = item.value;
                });
                if (choice == null) choice = value;
                return choice;
            }
            return null;
        }
    }
