export const mutations = {
    SET_USERS(state: any, users: any) {
        state.users = users;
    },
    SET_LOGINS(state: any, logins: any) {
        state.logins = logins;
    },
    SET_USERMODELS(state: any, userModels: any) {
        state.userModels = userModels;
    },
    SET_MODELUSERS(state: any, modelUsers: any) {
        state.modelUsers = modelUsers;
    },
    SET_MODELS(state: any, models: any) {
        state.models = models;
    },
    SET_SELECTEDUSER(state: any, selectedUser: any) {
        state.selectedUser = selectedUser;
    },
    SET_SELECTEDMODEL(state: any, selectedModel: any) {
        state.selectedModel = selectedModel;
    },
    SET_LOGINEDITING(state: any, loginEditing: any) {
        state.loginEditing = loginEditing;
    },
    SET_SCENARIOSTATUS(state: any, scenarioStatus: any) {
        state.scenarioStatus = scenarioStatus;
    },
};
