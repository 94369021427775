

    import { Action, Getter } from 'vuex-class';
    import { Component, Vue } from 'vue-property-decorator';


    const namespace = 'admin';

    @Component({
        components: {
        },
    })
    export default class AdminUsers extends Vue {

        @Getter('getModels', { namespace }) getModels: any;


        @Action('setSelectedModel', { namespace }) setSelectedModel: any;

        private filter = '';




        get filteredModels() {
            const _self = this;
            const _filteredModels = this.getModels.filter(function (model: any) {
                return _self.filter == '' || _self.filter == null || model.name.toLowerCase().includes(_self.filter.toLowerCase());
            });

            return _filteredModels;
        }

    }

