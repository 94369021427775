
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import PropertyValue from '@/components/main/PropertyValue.vue'

    @Component({
        components: {
            PropertyValue
        },
    })
    export default class ModelPropertyValue extends Vue {
        @Prop({ default: null }) public model: any;
        @Prop({ default: null }) public numberValue: any;
        
    }
